import dayjs  from "dayjs"
import { useEffect, useState } from "react"
import config from "react-global-configuration"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import clientActions from "client/services/actions"
import { Card } from "components/Card/Card"
import { FormPage } from "components/Form/Form"
import useOurForm from "components/Form/useForm"
import { ConsentModal, GenericModal } from "components/Modal/Modal"
import { RegisterAccountBelongsToChildModal } from "components/Modal/RegisterNirBelongsToChildModal"
import {
  HOME_PAGE,
  LOBBY_PAGE,
  VERIFY_PHONE_PAGE,
} from "core/constants"
import withNirReader from "hocs/withNirReader"
import { CarteVitaleInfoPage } from "./carteVitaleInfoDisplayer"
import { dateChildValidator, dateValidator } from "lib/form"
import { getBirthdateFromNir } from "lib/sesam"
import { BirthPlace, Prospect } from "types/entity"
import { InputFormProps, InputNames } from "types/props"
import { Dispatcher } from "types/redux"
import { Patient, RightsPeriod } from "types/sesam"
import { NirReaderResponse, SignupStore } from "types/store"
import signupActions from "./services/actions"
import { useLanguage } from "locales"
import { useBirthLocation } from "components/Form/birthLocationProvider"
import { getCurrentLanguage } from "locales/languages"
import { firstnameLabel } from "lib/utils"

const SHOW_CV_DATA_STEP = "SHOW_CV_DATA_STEP"
const INSERT_REQUIRED_DATA_STEP = "INSERT_REQUIRED_DATA_STEP"


const Signup = (
  props: SignupStore & { withNirReaderResponse?: NirReaderResponse }
): JSX.Element => {
  const { languages } = useLanguage()
  const { cleanBirthLocationData } = useBirthLocation()
  const navigate = useNavigate()
  const formRef = useOurForm()
  const [prospect, setProspect] = useState<Partial<Prospect>>({})
  const [modalAccountBelongsToChild, setModalAccountBelongsToChild] =
    useState(false)
  const [consentModalVisible, setConsentModalVisible] = useState(false)
  const [expiredRightPeriodsModalVisible, setExpiredRightPeriodsModalVisible] =
    useState(false)

  const [patientInNir, setPatientInNir] = useState({})
  const [signupByCv, setSignupByCv] = useState(false)
  const [step, setStep] = useState(INSERT_REQUIRED_DATA_STEP)

  const checkRightsPeriod = (rightsPeriod: RightsPeriod) => {
    const now = dayjs()
    // La date amo est elle expirée ?
    // On considère que si la date AMO est expirée,
    // la carte vitale n'est pas à jour
    if (rightsPeriod.end && rightsPeriod.end < now)
      setExpiredRightPeriodsModalVisible(true)
    if (rightsPeriod.start && rightsPeriod.start > now)
      setExpiredRightPeriodsModalVisible(true)
  }
  const checkAgeOfMainPatient = (nir: string) => {
    const birthdate = getBirthdateFromNir(nir)
    const age = dayjs().diff(birthdate, "year")
    if (age < 16) setModalAccountBelongsToChild(true)
  }

  useEffect(() => {
    // Save the response of nirReader to fill relatives and nir patient after nexmo success
    const newCustomer: Partial<Patient> | undefined =
      props.withNirReaderResponse?.patient
    if (props.withNirReaderResponse) {
      props.saveNirReaderResponse(props.withNirReaderResponse)
    }

    if (newCustomer?.nir) {
      checkAgeOfMainPatient(newCustomer.nir as string)
      setPatientInNir({ ...props.withNirReaderResponse.patient })
      setStep(SHOW_CV_DATA_STEP)
      setSignupByCv(true)
    }
    if (newCustomer?.amo?.periods) {
      checkRightsPeriod(newCustomer.amo?.periods)
    }
  }, [props.withNirReaderResponse])

  const saveProspect = (prospect: Prospect) => setProspect(prospect)

  const askConsent = () => setConsentModalVisible(true)

  const handlePseudoNameUpdate = (patient:Partial<Patient>) => {
    setPatientInNir(patient)
    props.saveNirReaderResponse({...props.withNirReaderResponse, patient: patient})
    setStep(INSERT_REQUIRED_DATA_STEP)
  }

  const onSubmit = () => {
    cleanBirthLocationData()
    let newProspect = prospect
    if (patientInNir){
        newProspect = {
          ...patientInNir,
          ...prospect,
        }
    }
    props.signup({
      ...newProspect,
    } as Prospect)
  }

  const inputsObj: InputFormProps[] = [
    { name: "gender", required: true },
    { name: InputNames.FIRST_BIRTH_FIRSTNAME, 
      label: <span>{firstnameLabel("customer")}</span>,
    condition: {
      type: "checkbox", label: languages.IuseAnotherFirstname,
      filled: { name: InputNames.FIRSTNAME, required: true},
    } },
    { name: InputNames.BIRTH_LASTNAME, condition: {
      label: languages.IuseAnotherLastname,
      type: "checkbox", filled: { name: InputNames.LASTNAME, required: true},
    } },
    {
      name: InputNames.INSEE_CODE,
      label: "",
      type: "hidden",
    },
    {
      name: InputNames.BIRTHDATE,
      required: true,
      rules: [
        dateValidator,
        dateChildValidator(16, () => {
          setModalAccountBelongsToChild(true)
        }),
      ],
    },
    { name: InputNames.BIRTH_COUNTRY, required: false, type:"hidden", default: {
      value: `${BirthPlace.FRANCE}`, label: "",
    } },
    { name: InputNames.BIRTH_LOCATION, required: true, readOnly: true },
    {
      name: "email",
      required: true,
      label: languages.provideYourMail,
      default: {
        warning: {
          title: languages.noEmailTitleModal,
          contentText: languages.noEmailContentModal,
          acceptText: languages.IHaveNoEmail,
          cancelText: languages.IwantToAddAnEmail,
          EmailDesign: true
        },
        value: config.get("mock.noemail"),
        label: languages.byPassEmail,
      },
    },
    { name: "phone", required: true },
    { name: "new-password", required: true, label: languages.createPassword },
  ]

  const formInputs = signupByCv ? [
    {
      name: "email",
      required: true,
      label: languages.provideYourMail,
      default: {
        warning: {
          title: languages.noEmailTitleModal,
          contentText: languages.noEmailContentModal,
          acceptText: languages.IHaveNoEmail,
          cancelText: languages.IwantToAddAnEmail,
          EmailDesign: true
        },
        value: config.get("mock.noemail"),
        label: languages.byPassEmail,
      },
    },
    { name: "phone", required: true },
    { name: "new-password", required: true, label: languages.createPassword },
  ] : inputsObj


  useEffect(() => {
    if (props.success) {
      props.reset()
      navigate(VERIFY_PHONE_PAGE)
    }
  }, [props.success])

  switch (step){
    case SHOW_CV_DATA_STEP:
      return (  
        <CarteVitaleInfoPage 
          patient ={patientInNir} 
          loading={props.loading} 
          handlePseudoNameUpdate={handlePseudoNameUpdate}
          onPrevious={()=> navigate(LOBBY_PAGE)}
        />
      )

    case INSERT_REQUIRED_DATA_STEP:
      return (
        <Card message={props.message}>
            <FormPage
              formRef={formRef}
              prospect={patientInNir}
              inputs={formInputs}
              acceptText={languages.register}
              loading={props.loading}
              onFinish={(values) => {
                saveProspect(values as Prospect)
                askConsent()
              }}
              onCancel={() => navigate(LOBBY_PAGE)}
            />

          <GenericModal
            visible={expiredRightPeriodsModalVisible}
            title={languages.nirIsExpiredTitle}
            contentText={languages.nirIsExpiredContent}
            cancelText={languages.backToHome}
            acceptText={languages.IUnderstandAndContinue}
            onCancel={() => navigate(HOME_PAGE)}
            onAccept={() => {
              setExpiredRightPeriodsModalVisible(false)
            }}
          />
          <RegisterAccountBelongsToChildModal
            visible={modalAccountBelongsToChild}
            onAccept={() => setModalAccountBelongsToChild(false)}
          />
          <ConsentModal
            visible={consentModalVisible}
            onCancel={() => {
              setConsentModalVisible(false)
            }}
            onAccept={() => {
              setConsentModalVisible(false)
              onSubmit()
            }}
          />
        </Card>
      )
  }
}

const mapDispatchToProps = (dispatch: Dispatcher): Partial<SignupStore> => {
  return {
    signup: (values: Prospect) => dispatch(signupActions.request(values)),
    saveNirReaderResponse: (values: NirReaderResponse) =>
      dispatch(clientActions.saveNirReaderResponse(values)),
    reset: () => dispatch(signupActions.reset()),
  }
}
const SignupWithNirReader = withNirReader(Signup)
const SignupPage = SignupWithNirReader

export default connect(
  (client: { signup: SignupStore }) => client.signup,
  mapDispatchToProps
)(SignupPage)
